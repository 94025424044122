.adjustingMap {
  width: 100%;
  height: 100%;
}

#map {
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 20px;
  border: 2px solid #1a1aff;
}
