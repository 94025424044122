html,
body {
  margin: 0;
  padding: 0;
}

.buttonClassForServiceClicked {
}

#idForOrangeClass.checkmarkClassForOrange:checked + .checkmarked {
  background-color: #ff9c40;
  border-color: #ff9c40;
}

.efectTextShadow {
  color: #fff;
}

.efectTextShadow:hover {
  text-shadow: 0px 0px 10px #fff;
}

.anotherFormToAdjust {
  background: #1a1aff;
  border-radius: 12px;
  margin-left: 10px;
  height: fit-content;
}

.adjustingBooleanOption {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
}

.changingPositionOfCheckMark {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  flex-wrap: wrap;
  color: #fff;
}

.adjustAtStart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.anotherFormToAdjust h3 {
  padding: 5%;
}

.error {
  border: 2px solid red;
}

.paddingForThisH2 {
  padding: 20px;
  padding-top: 60px;
}

.adjustingBlocksHour2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.adjustingBlocksHour2 {
  margin-top: 40px;
  margin-bottom: 15px;
}

.adjustingLabelAndInputServiceClicked.adjustingBlocksHour3 {
  background: #e0e8f1;
  margin-bottom: 4%;
}

.adjustTogheter {
  display: flex;
  align-items: center;
}

.adjustingThisH {
  padding: 10px;
}

#shorterText {
  max-width: 50px;
}

#mediumText {
  max-width: 100px;
}

#largeText {
  max-width: 250px;
}

#longestText {
  width: 450px;
}

/* .checkmarked2.checkmarkedAgain{
  width:25px;
} */

.recaptchaAndCheckmarkLine {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorHighlight {
  outline: 2px solid red;
}

.thisCheckMarkStyling {
  padding-left: 110px;
}

.adjustingThisH h1 {
  padding: 10px;
}

.adjustingLabelAndInputServiceClicked {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
}

.adjustingBothFormsDiferent {
  display: grid;
  grid-template-columns: 60% 40%;
  width: 700px;
}

.adjustingThisDivPadding {
  padding: 20px;
  text-align: center;
}

.textWithSameSize {
  text-align: left;
  width: 118px;
}

.textWithSameSize.textWithSameSize2 {
  width: 60px;
}

.centeringThisThings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  margin-top: 10px;
}

input,
label {
  margin: 10px;
}

/* .changingPositionOfCheckMark, .letChangeThisDisplay, .classForAdjustLabelChec{
  display:none;
} */

.adjustingBooleanOption h3 {
  animation: float2 4s infinite ease;
  cursor: pointer;
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 876px) {
  .adjustingBothFormsDiferent {
    display: flex;
    flex-direction: column;
  }

  .recaptchaAndCheckmarkLine {
    flex-direction: column;
  }

  .thisCheckMarkStyling {
    padding-left: 0;
  }

  .adjustTogheter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  .adjustingBothFormsDiferent {
    width: auto;
  }

  input {
    width: auto;
  }

  .textWithSameSize,
  .textWithSameSize.textWithSameSize2 {
    width: 95px;
  }

  #longestText {
    width: 180px;
  }
}

@media screen and (max-width: 595px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 508px) {
  .adjustingLabelAndInputServiceClicked {
    padding: 2%;
  }
}

@media screen and (max-width: 480px) {
  span {
    font-size: 12px;
  }
  .adjustingLabelAndInputServiceClicked{
    padding:20%;
    width:85%;
  }

  .centeringThisThings{
    display:flex;
    align-items:center;
    justify-content:center;
  }

  #myForm{
    
  }
  

  .buttonClassForServiceClicked,.buttonForVerifyData{
    height:10%;
    font-size:10px;
  }

  .adjustingBlocksHour2{
    display:flex;
    align-items:center;
    justify-content:center;
    
  }

  .adjustingThisDivPadding{
   display:flex;
   align-items:center;
   justify-content:center;
   flex-direction:column;
  }

  .changingPositionOfCheckMark.letChangeThisDisplay {
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .styledLabelForService{
    flex-direction:column;
  }

  .styledLabelForService input{
    height:100%;
  }
  .recaptchaGoogleServiceClicked{
    
  }

  .litleStyledService{
    width:130px;
  }

  .textWithSameSize.textWithSameSize2.textWithLowSizeService{
    width:60px;
  }

  .adjustingBothFormsDiferent {
    width: 200px;
  }
  .textWithSameSize,
  .textWithSameSize.textWithSameSize2 {
    width: 80px;
  }
  .recaptchaAndCheckmarkLine {
    width: 200px;
  }

  input {
    width: 150px;
    height:17px;
  }

  html {
    overflow-x: hidden;
  }

  input,
  label {
    margin: 4px;
  }

  #shorterText {
    max-width: 30px;
    height:17px;
  }

  #mediumText,
  #largeText,
  #longestText {
    max-width: 150px;
    height:17px;
  }

  .adjustingThisH h1 {
    padding: 1px;
  }

  .adjustingThisH {
    padding: 1px;
  }

  .recaptchaStyle {
    transform: scale(0.6);
  }

  .adjustingThisH h1 {
    padding: 30px;
  }

  .dadFit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
  }
}

@media screen and (max-width: 356px) {
  html {
    overflow-x: auto;
  }
}
