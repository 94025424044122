.thisHeaderStyleBecomeDelivery{
  height:9.3em;
}

.divSeparingStyleHeaderBecomeDelivery{
  top:0%;
}

.beOurGreatDelivery{
  position:relative;
  top:-7.5em;
}

.beOurGreatDelivery h1{
  color:#ff9c40;
}

.backgroundOfServiceBecomeDelivery {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfServiceBecomeDelivery::before {
  content: "";
  position: absolute;
  top: -5.5em;
  left: 0;
  width: 100%;
  min-width:335px;
  height: 23.6em;
  background-image: url("/public/backgroundBecomeADelivery.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5em;
  opacity: 0.3;
}

.adjustingLabelAndInput2Delivery {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
  margin-top:20px;
  max-width:700px;
}

.adjustingFormToBecameDelivery{
  margin-bottom:30px;
}

.adjustingDivForForDelivery{
  display:flex;
  align-items:center;
  justify-content:center;
}

.forHBecomeDelivery2{
  margin:20px;
}

.adjustAtStart3Delivery {
  display: flex;
  position:relative;
  right:75px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.error {
  border: 2px solid red;
}

.adjustingLabelAndInput2Delivery {
  background: #e0e8f1;
}

.adjustTogheter3Delivery {
  display: flex;
  align-items: center;
}

#shorterTextDelivery {
  max-width: 50px;
}

#mediumTextDelivery {
  max-width: 100px;
}

#largeTextDelivery {
  max-width: 250px;
}

#longestTextDelivery {
  width: 450px;
}

.recaptchaAndCheckmarkLine3Delivery {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorHighlight {
  outline: 2px solid red;
}

.thisCheckMarkStyling3Delivery {
  padding-left: 110px;
}

.adjustingLabelAndInput2Delivery {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
}

.textWithSameSize3Delivery {
  text-align: left;
  width: 118px;
}

.textWithSameSize3Delivery.textWithSameSize4Delivery {
  width: 60px;
}

.centeringThisThingsDelivery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  margin-top: 10px;
}

input,
label {
  margin: 10px;
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 876px) {

  .adjustAtStart3Delivery {
    position:static;
  }

  .recaptchaAndCheckmarkLine3Delivery {
    flex-direction: column;
  }

  .thisCheckMarkStyling3Delivery {
    padding-left: 0;
  }

  .adjustTogheter3Delivery {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  input {
    width: auto;
  }

  .textWithSameSize3Delivery,
  .textWithSameSize3Delivery.textWithSameSize4Delivery {
    width: 95px;
  }

  #longestTextDelivery {
    width: 180px;
  }
}

@media screen and (max-width: 555px) {
  body {
    overflow-x: hidden;
  }
 .beOurGreatDelivery h1{
  font-size:34px;
 }
}

@media screen and (max-width: 480px) {
  span {
    font-size: 12px;
  }

  .textWithSameSize3Delivery,
  .textWithSameSize3Delivery.textWithSameSize4Delivery {
    width: 80px;
  }
  .recaptchaAndCheckmarkLine3Delivery {
    width: 200px;
  }

  input {
    width: 120px;
  }

  html {
    overflow-x: hidden;
  }

  input,
  label {
    margin: 4px;
  }

  #shorterTextDelivery {
    max-width: 30px;
  }

  #mediumTextDelivery,
  #largeTextDelivery,
  #longestTextDelivery {
    max-width: 120px;
  }

  .recaptchaStyle {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 399px) {
  .beOurGreatDelivery h1{
    font-size:30px;
  }
}

@media screen and (max-width: 356px) {
  html {
    overflow-x: auto;
  }
}
