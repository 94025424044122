.buttonClassTimePayment{
  margin-bottom:4%;
}

.formForTimeToPayment{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.divIntoFormPayment{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  background:#ffe0b2;
  border-radius:10%;
  padding:20px;
  margin-bottom:40px;
}

.dateCard,.CVCCard{
  width:140px;
}

.adjustingKeyForm{
  display:flex;
}

.formForPixPayment{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:20px;
}

.adjustingInsideFormPix{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  width:310px;
  background:#ffe0b2;
  border-radius:10%;
  padding:20px;
  margin-bottom:40px;
}

.adjustingKeyForm{
  padding:20px;
}

.qrCodePix{
  padding:20px;
  margin-top:20px;
  border-radius:10%;
  width:200px;
  background:#fff;
}

.keyForPix{
  background:#fff;
  border-radius:10%;
  padding:4px;
  margin-left:10px;
}

.backgroundOfServicePayment {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfServicePayment::before {
  content: "";
  position: absolute;
  top: -88px;
  left: 0;
  width: 100%;
  min-width:335px;
  height: 58.5vh;
  background-image: url("/public/backgroundPayment.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
}

.thisHeaderStylePayment{
  height:23vh;
  margin-bottom:5%;
}

.divSeparingStyleHeaderPayment{
  top:15%;
}


@media screen and (max-width:336px){
  .divIntoFormPayment, .adjustingInsideFormPix{
    max-width:200px;
    max-height:auto;
  }
  .qrCodePix{
    max-height:50%;
    max-width:50%;
  }

  .qrCodePix img{
    max-height:50px;
    max-width:50%;
  }
}