.adjustingAllVideosCourse{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.videoContainer {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.backgroundForThisCourse {
  position: relative;
  width: 100%;
  height: 100%;
}

.backgroundForThisCourse::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-image: url("https://cdn.pixabay.com/photo/2018/04/14/17/57/instagram-3319588_1280.jpg");
  opacity: 0.2;
  background-repeat: no-repeat;
  background-size: cover;
  z-index:1;
}

.adjustingVideosCourses{
  display: grid;
  grid-template-columns:repeat(4, 400px);
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  max-width:1000px;
}

.adjustingInsideCourse{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  text-align:center;
  width:280px;
  padding:30px;
  text-wrap:wrap;
  background-color: transparent;
  transition: background-color 0.5s ease;
  border-radius:10%;
}

.textWidthAdjusting{
  max-width:270px;
  text-wrap:wrap;
}

.adjustingInsideCourse:hover{
  background-color: #ffe0b2;
}

@media screen and (max-width:1016px){
  .adjustingVideosCourses{
    max-width:800px;
  }
}

@media screen and (max-width:816px){
  .adjustingVideosCourses{
    max-width:600px;
  }
}

@media screen and (max-width:616px){
  .adjustingVideosCourses{
    max-width:400px;
  }
}

@media screen and (max-width:416px){
  .adjustingVideosCourses{
    max-width:300px;
  }
}