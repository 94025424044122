input{
  border-radius:10px;
  width:300px;
  height:30px;
  outline:none;
  border:none;
}

input:focus{
  box-shadow: 0px 0px 10px 0px #fff;
}

@media screen and (max-width:370px){
  input{
    width:auto;
  }
}

@media screen and (max-width:387px){
  input{
    width:200px;
  }
}