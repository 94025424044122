.searchIconBeforeServices{
    cursor: pointer;
    height: 30px;
    width: 30px;
}

.adjustingItems2 {
  display: flex;
  cursor: pointer;
  max-width: 250px;
  color: #1a1aff;
  padding: 10px;
}

.paddingBottomLi {
  padding: 10px;
  width: 300px auto;
}

.adjustingBlock2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.adjustUlStyle2 {
  break-inside: avoid;
  background:#EBF5FF;
  margin: 6px;
  border-radius:7%;
}

.adjustingIcons2 {
  width: 40px;
  height: 40px;
}

.invisible {
  display: none;
}

* {
  margin: 0;
}

html,
body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 40px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

p {
  font-size: 18px;
}

.senhoraAndando2 {
  width: 340px;
  height: 190px;
  opacity: 0;
}

.ladyAndServicePadding2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.slideRight2 {
  padding: 20px;
}

.slideRight2 {
  animation: slideRight 3s forwards;
}

.centeringImageAndText2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.backgroundOfService2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfService2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72.2vh;
  background-image: url("/public/backgroundGroup2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
}

.positionInputSearch2 {
  position: relative;
  bottom: 5vh;
}

.searchIcon2 {
  cursor: pointer;
  font-size: 20px;
}

.oneSearchItem2 {
  display: flex;
  align-items: center;
}

.removingDecorationServices2 {
  text-decoration: none;
  color: #ff9c40;
}

.removingDecorationServices3 {
  text-decoration: none;
  color: #1a1aff;
}

.imagesGroup2 {
  width: 30%;
  height: 30%;
  padding: 10px;
}

.imageFloating2 {
  animation: float 4s infinite ease-in-out;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1115px) {
  .backgroundOfService2::before {
    height: 79.5vh;
  }
}

@media screen and (max-width: 962px) {
  .backgroundOfService2::before {
    height: 87vh;
  }
}

@media screen and (max-width: 876px) {
  .backgroundOfService2::before {
    height: 79.6vh;
  }
}

@media screen and (max-width: 831px) {
  .backgroundOfService2::before {
    height: 87vh;
  }
}

@media screen and (max-width: 823px) {
  .backgroundOfService2::before {
    height: 86.9vh;
  }
}

@media screen and (max-width: 790px) {
  .backgroundOfService2::before {
    height: 101.1vh;
  }
  .backgroundOfService2 {
    flex-direction: column;
  }
}

@media screen and (max-width: 692px) {
  .backgroundOfService2::before {
    height: 101vh;
  }
}

@media screen and (max-width: 680px) {
  section {
    flex-direction: column;
  }
  .reverseImage2 {
    flex-direction: column;
  }
  .imagesGroup2 {
    width: 50%;
    height: 50%;
  }
  .separingTextFromImage2 {
    width: 70%;
  }
}

@media screen and (max-width: 572px) {
  .backgroundOfService2::before {
    height: 108.4vh;
  }

  .adjustingIcons2 {
    height: 30px;
    width: 30px;
  }

  .adjustingItems2 {
    padding: 10px;
  }

  .adjustingItems2 h1 {
    font-size: 22px;
  }

  .paddingBottomLi {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .backgroundOfService2::before {
    height: 106.5vh;
  }
}

@media screen and (max-width: 455px) {
  .backgroundOfService2::before {
    height: 106.4vh;
  }
}

@media screen and (max-width: 422px) {
  .backgroundOfService2::before {
    height: 114vh;
  }
}

@media screen and (max-width: 396px) {
  .senhoraAndando2 {
    max-width: 250px;
    max-height: 200px;
  }
}

@media screen and (max-width: 383px) {
  .backgroundOfService2::before {
    height: 121.7vh;
  }
}

@media screen and (max-width: 356px) {
  .backgroundOfService2::before {
    width: auto;
    min-width: 340px;
  }
  html {
    max-width: 356px;
  }
  .backgroundOfService2 {
    min-width: 290px;
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  .backgroundOfService2::before {
    width: auto;
    min-width: 335px;
  }
  html {
    max-width: 346px;
  }
  .backgroundOfService2 {
    min-width: 290px;
    width: auto;
  }
}

@media screen and (max-width: 346px) {
  .backgroundOfService2::before {
    width: auto;
    min-width: 335px;
  }
  html {
    max-width: 356px;
  }
  .backgroundOfService2 {
    min-width: 290px;
    width: auto;
  }
}

@media screen and (max-width: 340px) {
  .backgroundOfService2::before {
    width: auto;
    min-width: 331px;
  }
  html {
    max-width: 356px;
  }
  .backgroundOfService2 {
    min-width: 290px;
    width: auto;
  }
}

@media screen and (max-width: 336px) {
  .backgroundOfService2::before {
    width: auto;
    min-width: 338px;
  }
  html {
    width: 336px;
  }
  .backgroundOfService2 {
    min-width: 290px;
    width: auto;
  }
}

@media screen and (max-width: 291px) {
  .backgroundOfService2 {
    min-width: 298px;
    width: auto;
  }
}
