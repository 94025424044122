.adjustingTopHeaderInvitingCourses {
  top: 13px;
}

.buttonStyleForInvitingToCourses{
  min-height: 1em;
  background-position: 90%;
}

.buttonInvitingCoursesRealizePayment{
  background-position: 97%;
}

.classNameHamburguerMenu {
  top: 2em;
  left: 99em;
}

.adjustingImageAndTitleInvitingCourses {
  display: flex;
  align-items: bottom;
  justify-content: center;
  max-width: 600px;
}

.aproovedInvitingCourses {
  width: 48px;
  height: 48px;
}

.allBackgroundInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
}

.beOurGreatInvitingCourses {
  position: relative;
  bottom: 80px;
}

.beOurGreatInvitingCourses h1 {
  color: #ff9c40;
}

.backgroundOfServiceInvitingCourses {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  min-width: 100vw;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
  height: 120px;
}

.backgroundOfServiceInvitingCourses::before {
  content: "";
  position: absolute;
  left: 0;
  max-width: 100%;
  min-width: 100%;
  background-image: url("/public/backgroundInvitingCourses.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
  height: 130%;
}

.principalDivInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.anotherColorFormInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputInvitingToCoursesEmail {
  border: 2px solid #1a1aff;
}

.showingInvitingCourses {
  padding-bottom: 2%;
}

.adjustingDivInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 2%; */
  margin: 2%;
  text-align: center;
  max-width: 800px;
}

.adjustingDivInvitingCourses > * {
  /* padding: 2%; */
  margin: 2%;
}

.formForTimeToInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.divIntoFormPaymentInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffe0b2;
  border-radius: 10%;
  padding: 20px;
  margin-bottom: 40px;
}

.dateCard,
.CVCCard {
  width: 140px;
}

.adjustingKeyFormInvitingCourses {
  display: flex;
}

.formForPixPaymentInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.adjustingInsideFormPixInvitingCourses {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 310px;
  background: #ffe0b2;
  border-radius: 10%;
  padding: 20px;
  margin-bottom: 40px;
}

.adjustingKeyFormInvitingCourses {
  padding: 20px;
}

.qrCodePix {
  padding: 20px;
  margin-top: 20px;
  border-radius: 10%;
  width: 200px;
  background: #fff;
}

.keyForPix {
  background: #fff;
  border-radius: 10%;
  padding: 4px;
  margin-left: 10px;
}

@media screen and (max-width: 778px) {

  .adjustingDivInvitingCourses {
    position: relative;
    bottom: 5em;
  }
  .beOurGreatInvitingCourses {
    padding: 1%;
    bottom: 150px;
  }
  .backgroundOfServiceInvitingCourses {
    min-height: 280px;
    /* min-width:300px; */
  }
  .backgroundOfServiceInvitingCourses::before {
    max-height: 599px;
    /* min-width:300px; */
  }
  body {
    min-width: 345px;
  }
}

@media screen and (max-width: 478px) {
  .classNameHamburguerMenu {
    left: 103em;
  }
}

@media screen and (max-width: 411px) {
  /* .adjustingDivInvitingCourses {
    position: relative;
    bottom: 238px;
  } */
  .backgroundOfServiceInvitingCourses::before {
    min-height: 730px;
  }
  .backgroundOfServiceInvitingCourses {
    min-height: 410px;
  }

  .beOurGreatInvitingCourses {
    bottom: 250px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .principalDivInvitingCourses {
    padding-top: 0px;
    margin-top: 0px;
  }

  .backgroundOfServiceInvitingCourses {
    bottom: 100px;
  }

  .adjustingDivInvitingCourses {
    position: relative;
    bottom: 10em;
  }
}

@media screen and (max-width: 400px) {
  .adjustingDivInvitingCourses {
    position: relative;
    bottom: 220px;
  }
}

@media screen and (max-width: 379px) {
  .divIntoFormPaymentInvitingCourses,
  .adjustingInsideFormPixInvitingCourses {
    max-width: 200px;
    max-height: auto;
  }

  .showingInvitingCourses{
    position: relative;
    top: -13em;
  }
  
  .qrCodePix {
    max-height: 50%;
    max-width: 50%;
  }

  .qrCodePix img {
    max-height: 50px;
    max-width: 50%;
  }

  .beOurGreatInvitingCourses {
    bottom: 350px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 336px) {
  .classNameHamburguerMenu{
    left:104em;
      }
  .backgroundOfServiceInvitingCourses::before {
    min-height: 1000px;
  }
  .backgroundOfServiceInvitingCourses {
    min-height: 480px;
  }
  body,
  html,
  .allBackgroundInvitingCourses {
    min-width: 340px;
  }

  .adjustingDivInvitingCourses {
    position: relative;
    bottom: 25vh;
  }
}
