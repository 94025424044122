.adjustingImageAndTitleBecomeStudent{
  display:flex;
  align-items:center;
  justify-content:center;
}

.burguerMenuIsOpenClass.burguerMenuIsOpenClassOpnened.burguerMenuIsOpenClassFullTry{
  left: 0em;
}

.classNameHamburguerMenuBecomeStudent{
 left: 35em;
}

.studentSimbollBecomeStudent{
  width:48px;
  height:48px;
}

.thisHeaderStyleBecomeStudent{
  height:9.2em;
  min-width:300px;
}

.divSeparingStyleHeaderBecomeStudent{
  top:0%;
}

.beOurGreatStudent{
  position:relative;
  top:-7.5em;
}

.beOurGreatStudent h1{
  color:#ff9c40;
}

.backgroundOfServiceBecomeStudent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfServiceBecomeStudent::before {
  content: "";
  position: absolute;
  top: 0em;
  left: 0;
  width: 100%;
  min-width:335px;
  height: 23em;
  background-image: url("/public/backgroundBecomeAStudent.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* padding-top: 80px; */
  opacity: 0.3;
}

.adjustingLabelAndInput2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
  margin-top:20px;
  max-width:700px;
}

.adjustingFormToBecameStudent{
  margin-bottom:30px;
}

.adjustingDivForForStudent{
  display:flex;
  align-items:center;
  justify-content:center;
}

.forHBecomeStudent2{
  margin:20px;
}

.adjustAtStart3 {
  display: flex;
  position:relative;
  right:75px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.error {
  border: 2px solid red;
}

.adjustingLabelAndInput2 {
  background: #e0e8f1;
}

.adjustTogheter3 {
  display: flex;
  align-items: center;
}

#shorterTextStudent {
  max-width: 50px;
}

#mediumTextStudent {
  max-width: 100px;
}

#largeTextStudent {
  max-width: 250px;
}

#longestTextStudent {
  width: 450px;
}

.recaptchaAndCheckmarkLine3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorHighlight {
  outline: 2px solid red;
}

.thisCheckMarkStyling3 {
  padding-left: 110px;
}

.adjustingLabelAndInput2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
}

.textWithSameSize3 {
  text-align: left;
  width: 118px;
}

.textWithSameSize3.textWithSameSize4 {
  width: 60px;
}

.centeringThisThings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  margin-top: 10px;
}

input,
label {
  margin: 10px;
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 876px) {

  .adjustAtStart3 {
    position:static;
  }

  .recaptchaAndCheckmarkLine3 {
    flex-direction: column;
  }

  .thisCheckMarkStyling3 {
    padding-left: 0;
  }

  .adjustTogheter3 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  input {
    width: auto;
  }

  .textWithSameSize3,
  .textWithSameSize3.textWithSameSize4 {
    width: 95px;
  }

  #longestTextStudent {
    width: 180px;
  }
}

@media screen and (max-width: 555px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 480px) {
  span {
    font-size: 12px;
  }

  .textWithSameSize3,
  .textWithSameSize3.textWithSameSize4 {
    width: 80px;
  }
  .recaptchaAndCheckmarkLine3 {
    width: 200px;
  }

  input {
    width: 120px;
  }

  html {
    overflow-x: hidden;
  }

  input,
  label {
    margin: 4px;
  }

  #shorterTextStudent {
    max-width: 30px;
  }

  #mediumTextStudent,
  #largeTextStudent,
  #longestTextStudent {
    max-width: 120px;
  }

  .recaptchaStyle {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 399px) {
  .beOurGreatStudent h1{
    font-size:30px;
  }
}

@media screen and (max-width: 356px) {
  html {
    overflow-x: auto;
  }
}
