.whatsAppIcon {
  position: fixed;
  bottom: 130px;
  right: 10px;
  width: 60px;
  height: 60px;
  z-index: 1;
  animation: floatWhatsApp 4s infinite ease;

}

@keyframes floatWhatsApp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
