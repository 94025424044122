* {
  margin: 0;
}

section p{
  text-align: justify;
  text-justify: initial;
} 
/* aqui no section mudar */

.titleForSectionHome{
  min-width: 9em;
  font-size: 2.2em;
  padding-bottom: 1em;
}


html,
body {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 40px;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

p {
  font-size: 18px;
}

section {
  min-height: 40vh;
  height: auto;
  color: #1a1aff;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 
.adjustingTopHeaderHome{
  top:13px;
}

.backgroundOfServiceHome{
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 100%;
  min-width:100vw;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
  height:120px;
}

.backgroundOfServiceHome::before{
  content: "";
  position: absolute;
  left: 0;
  max-width: 100%;
  min-width:101vw;
  background-image: url("/public/backgroundGroup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
  height:130%;
} */

.senhoraAndando {
  width: 340px;
  height: 190px;
  opacity: 0;
}

.adjustingNames p {
  color: #fff;
}

.ladyAndServicePadding {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em;
  /* mudar */
  margin-top: 10%;
}

.adjustingNames {
  text-align: center;
  max-width: 300px;
  padding-bottom: 0.3125em;
  padding-top: 0.625em;
  position: relative;
  bottom: 1.3125em;
}

.slideRight {
  padding: 20px;
}

.slideRight {
  animation: slideRight 3s forwards;
}

.centeringImageAndText {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* .backgroundOfService {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfService::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 72.4vh;
  background-image: url("/public/backgroundGroup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
}  */

.backgroundOfService {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
  height: 19em;
}

.backgroundOfService::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 466px;
  background-image: url("/public/backgroundGroup.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
  max-height: 450px;
}

.reverseImage {
  flex-direction: row-reverse;
}

.positionInputSearch {
  position: relative;
  bottom: 5vh;
}

.searchIcon {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.oneSearchItem {
  display: flex;
  align-items: center;
}

.removingDecorationServices {
  text-decoration: none;
  color: #ff9c40;
}

.imagesGroup {
  width: 20em;
  height: 10em;
  padding: 10px;
}

.imagesGroupSmartphone {
  width: 4em;
  height: 8em;
  padding: 10px;
}

.separingTextFromImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  padding: 10px;
}

.separingTextFromImage2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 54%;
  padding: 10px;
}

.imageFloating {
  animation: float 4s infinite ease-in-out;
}

@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1062px) {
  .backgroundOfService::before {
    height: 79.6vh;
  }
}

@media screen and (max-width: 825px) {
  .backgroundOfService::before {
    height: 87vh;
  }
}

@media screen and (max-width: 790px) {
  .backgroundOfService::before {
    min-height: 36.1em;
  }

  .backgroundOfService {
    flex-direction: column;
    height: 27em;
  }

  .slideRight {
    position: relative;
    bottom: 1em;
  }
}

@media screen and (max-width: 692px) {
  /* .backgroundOfService::before {
    height: 107vh;
  } */
}

@media screen and (max-width: 680px) {
  section {
    flex-direction: column;
  }

  .reverseImage {
    flex-direction: column;
  }

  /* .imagesGroup {
    width: 50%;
    height: 50%;
  } */

  .separingTextFromImage {
    width: 70%;
  }

  .separingTextFromImage2 {
    width: 70%;
  }
}

@media screen and (max-width: 455px) {

  /* .backgroundOfService::before {
    height: 77.22em;
  } */
  .backgroundOfService {
    padding-top: 4em;
  }

  .backgroundOfService::before {
    min-height: 40.1em;
  }

  .inputGenericHomeToService {
    min-width: 17em;
    min-height: 2em;
  }

  .hamburguerForHome {
    position: relative;
    bottom: 4em;
  }

  .hamburguerMenuForHome {}

  .classNameAnchorForHome {
    position: relative;
    font-size: 22px;
    left: 2em;
  }
  .classNameAnchorForHome span{
    font-size: 1em;
  }

  .oneSearchItem {
    min-width: 4em;
  }

  .slideRight {
    font-size: 28px;
  }
}

@media screen and (max-width: 396px) {
  .titleForSectionHome{
    font-size: 1.85em;
  }
  /* .inputGenericHomeToService {
    min-width: 17em;
    min-height: 2em;
  }

  .hamburguerForHome {
    position: relative;
    bottom: 4em;
  }

  .hamburguerMenuForHome {}

  .classNameAnchorForHome {
    position: relative;
    font-size: 22px;
    left: 2em;
  }
  .classNameAnchorForHome span{
    font-size: 14px;
  }

  .oneSearchItem {
    min-width: 4em;
  }

  .slideRight {
    font-size: 28px;
  } */

  .classNameAnchorForHome {
    left: 1em;
  }

  .classNameAnchorForHome span{
    font-size: 0.75em;
  }

  .senhoraAndando {
    max-width: 15.625em;
    max-height: 12.5em;
  }

  .adjustingNames {
    padding-top: 2em;
  }

  /* .backgroundOfService {
   min-height:23em;
  }
  .backgroundOfService::before {
    min-height:32.2em;
   } */
}

@media screen and (max-width: 356px) {
  .backgroundOfService::before {
    width: auto;
    min-width: 21.3em;
  }

  html {
    max-width: 22.25em;
  }

  .backgroundOfService {
    min-width: 18.125em;
    width: auto;
  }
}

@media screen and (max-width: 350px) {
  .backgroundOfService {
    min-width: 21em
  }

  /* .backgroundOfService::before {
    width: auto;
    min-width: 335px;
  } */
  /* html {
    max-width: 346px;
  } */
  /* .backgroundOfService {
    min-width: 290px;
    width: auto;
  } */
}

@media screen and (max-width: 346px) {
  .backgroundOfService::before {
    min-width: 21.1em
  }

  /* .backgroundOfService::before {
    width: auto;
    min-width: 335px;
  } */
  /* html {
    max-width: 356px;
  } */
  /* .backgroundOfService {
    min-width: 290px;
    width: auto;
  } */
}

@media screen and (max-width: 340px) {
  /* .backgroundOfService::before {
    width: auto;
    min-width: 331px;
  } */
  /* html {
    max-width: 356px;
  } */
  /* .backgroundOfService {
    min-width: 290px;
    width: auto;
  } */

}

@media screen and (max-width: 336px) {

  /* .backgroundOfService::before {
    width: auto;
    min-width: 338px;
  } */
  /* html {
    width: 336px;
  } */
  /* .backgroundOfService {
    min-width: 290px;
    width: auto;
  } */
  .backgroundOfService::before {
    min-width: 21.3em
  }
}

@media screen and (max-width:310px){
  .hamburguerMenuForHome{
  left: -1em;
  }
}

@media screen and (max-width: 291px) {
  .headerLogoForHome{
    position: relative;
    left:1em;
  }
  .separingLogoHeaderForHome{
    position: relative;
    left:1em;
  }

  .backgroundOfService {
    min-width: 18.625em;
    width: auto;
  }
}