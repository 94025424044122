input[type="radio"] {
  display: none;
}

.checkbox-listed{
  display:flex;
}

.checkmarked {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  cursor: pointer;
  margin-right: 10px;
}

input[type="radio"]:checked + .checkmarked {
  background-color: #007bff;
  border-color: #007bff;
}

@media screen and (max-width:480px){
  .checkbox-listed{
    font-size:10px;
  }

  .checkmarked{
    width:10px;
    height:10px;
  }

}
