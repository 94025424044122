.adjustFooter {
  position: relative;
  display: flex;
  bottom: 0;
  min-height: 20vh;
  flex-direction: column;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
}

.allImagesSize img{
  width:30px;
  height:30px;
  padding:5px;
}

footer {
  flex: 1;
  position: relative;
  display: flexbox;
  bottom: 0;
  height: 20vh;
  padding-bottom: 40px;
  color: #ff9c40;
}

footer h1,
footer p {
  position: relative;
  display: flex;
  bottom: 0px;
  padding: 20px;
}

footer p{
  color:#fff;
  padding: 2em;
}