body {
  color: #1a1aff;
  text-align: center;
}

#map {
  width: 250px;
  height: 250px;
  overflow: hidden;
  margin: 20px;
  border: 2px solid #1a1aff;
}

.containerTopServices {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.alignedTopServices {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 700px;
}

.adjustFriendPersonList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.possibleFriends {
  margin: 40px;
}

.pictureFriends {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin: 20px;
}

.pictureFriends img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  /* display: block;
  position: relative;
  top: 0;
  left: 0; */
  transform: translate(0, 0);
  cursor: default;
}

.servicesAdjustingImages {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width:100%;
}

.servicesBlockImages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background:#F5F5F5;
  border-radius:12px;
  padding:5%;
}