.divForPaymentRealized{
  display:flex;
  align-items:center;
  justify-content:center;
}

.adjustingPaymentRealizedInformation{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding: 4%;
  width:700px;
}

.adjustingPaymentRealizedInformation h1,.adjustingPaymentRealizedInformation p{
  padding:1%;
}