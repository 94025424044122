button{
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-width:0;
    border-style:none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    cursor: pointer;
    margin-left: 0;
    margin-top: 12px;
    margin-right: 0;
    margin-bottom: 0;
    border:2px solid #1a1aff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 25px;
    padding-right: 25px;
    color: #FFFFFF;
    background-size: 40em;
    background-position: 10em;
    background-image: linear-gradient(45deg, #fff 50%, #1a1aff 50%);
    transition: background 500ms ease, color 500ms ease;
  }

  button:hover{
    background-position:0;
    background: #fff;
    color:#1a1aff;
  }