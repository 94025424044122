html,body{
  margin:0;
  padding:0;
  display:flex;
  align-items:center;
  justify-content:center;
}

.centeringCourseName{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.adjustingCourses{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:20px;
}

.adjustingCourses:hover{
  background:#F5F5F5;
  border-radius:10px;
}

.adjustingCourseTemplate{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  padding:10px;
}

img{
  height:165px;
  width:165px;
  cursor:pointer;
}

@media screen and (max-width: 736px){
  .adjustingCourseTemplate{
    max-width: 700px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 716px){
  .adjustingCourseTemplate{
    max-width: 500px;
    overflow-x: auto;
  }
}

@media screen and (max-width: 516px){
  .adjustingCourseTemplate{
    max-width: 300px;
  }
}
