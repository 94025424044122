.adjustingAccountCreatedFriend{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding:4%;
  width:50%;
}

.divForCenterCreatedFriend{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}