header {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  height: 0.3em;
  width: 100em auto;
  margin: 0;
  padding: 1.25em;
  padding-bottom: 12.5em;
}

.textHeaderOneLine {
  text-wrap: nowrap;
}

.justTheSizeAdjustingHeader {
  height: 67.5em;
  min-width: 120em;
}
/* .thisHeaderStyleEspecific{
  height:22.8vh;
} */

.hamburguerMenuAdjustedOpen{
  display:flex;
  z-index:9999;
  align-items:center;
  justify-content:center;
  position:relative;
}

.showBurguerMenu.burguerMenuIsOpen {
  width: 1em;
}

.adjustingCloseIcon {
  width: 32px;
  height: 32px;
}

/* .adjustingCloseItemMenu {
  display: flex;
  position: relative;
  left:15em;
  bottom:7em;
  height: 30px;
  border-radius: 7px;
} */


.adjustingCloseItemMenu {
  position: fixed;
  top:10%;
  left: 90%;
  transform: translate(-50%, -50%);
  display: flex;
  height: 30px;
  border-radius: 7px;
}


.adjustingCloseItemMenu:hover {
  box-shadow: 0px 0px 10px #fff;
}

.adjustItems {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.separingItems {
  display: flexbox;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 3.75em;
}

.separingLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.625em;
}

.logo {
  height: 104px;
  width: 190px;
}

header a,
header .a {
  cursor: pointer;
  color: #ff9c40;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 900;
  text-decoration: none;
  padding: 25px;
  z-index: 2;
}

header .a:hover,
header a:hover {
  color: #fff;
  text-shadow: 0px 0px 10px #fff;
}

.showBurguerMenu {
  display: none;
}

@media screen and (max-width: 746px) {
  html {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 716px) {
  .separingItems {
    top: 78px;
  }
}

@media screen and (max-width: 685px) {
  header {
    min-width: 220px;
    max-width: 100vw;
  }
  html {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .justTheSizeAdjustingHeader {
    background: #1a1aff;
  }

  .lowerWidth {
    display: none;
  }
  .showBurguerMenu {
    display: flex;
    font-size: 40px;
  }

  .showBurguerMenu.burguerMenuIsOpen {
    min-width: 120em;
  }

  .necessaryForHambuguerAdjustHeader > .hamburguerMenuHeader {
    display: flex;
    position: relative;
    top: 0em;
  }
  .separingLogo .logo,
  .separingLogo {
    position: relative;
    top: 0.09em;
    left: 15vw;
    height: 4.4375em;
    width: 8.125em;
  }

  .adjustItems,
  .adjustItems.ancorBecomeStudent,
  .adjustItems.ancorBecomeFriend {
    display: flex;
    position: relative;
    left: 0.1em;
  }

  .hamburguerMenuHeader {
    font-size: 40px;
  }

  .hamburguerMenuAdjustedOpen{
    left:131em;
    top:2em;
  }
}

@media screen and (max-width: 597px) {
  html {
    overflow-y: auto;
  }

  .showBurguerMenu.burguerMenuIsOpen {
    width: 120em;
  }
  .hamburguerMenuAdjustedOpen{
    left:136em;
    top:2em;
  }
}

@media screen and (max-width: 520px) {
  .necessaryForHambuguerAdjustHeader > .hamburguerMenuHeader {
    right: 0.1em;
  }
}

@media screen and (max-width: 508px) {
  /* .necessaryForHambuguerAdjustHeader > .hamburguerMenuHeader {
    right: 12em;
  } */
  .adjustItems {
  }
}

@media screen and (max-width:480px){
  .hamburguerMenuAdjustedOpen{
    left:141em;
    top:2em;
  }
}

@media screen and (max-width: 396px) {
  .separingLogo .logo,
  .separingLogo {
    left: 10vw;
  }
  .hamburguerMenuAdjustedOpen{
    left:141em;
    top:2em;

  }
  
  
}

@media screen and (max-width: 336px) {
  .hamburguerMenuAdjustedOpen{
    top:2em;
    left:107em;
  }
  .adjustingCloseItemMenu {
    left: 13em;
  }
  .justTheSizeAdjustingHeader {
    min-width: 20.625em;
  }

  .justTheSizeAdjustingHeaderForOtherViews {
    background: linear-gradient(to left, #1a1aff 95%, #00008b 100%);
  }

  .showBurguerMenu.burguerMenuIsOpen {
    right: 0.625em;
    min-width: 1em;
  }
}

@media screen and (max-width: 282px) {
.adjustingCloseItemMenu{
  left: 80%;
}
}


/* .burguerMenuIsOpen {
  position: absolute;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  z-index: 222;
  height: 62.5em;
  right: 0;
  width: 1em;
  min-width: 120em;
}

.adjustingOptionsMenuForAll {
  position: relative;
  top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
} */

.burguerMenuIsOpen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  z-index: 222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adjustingOptionsMenuForAll {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.adjustingOptionsMenu {
  display: flex;
  z-index: 99999;
  color: #ff9c40;
}

.hamburguerMenuHeader {
}
