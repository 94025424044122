.rangeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rangeWrapper span {
  margin-bottom: 0.5rem;
}

.rangeWrapper input[type="range"] {
  width: 100%;
  margin: 0;
}

.rangeLabels {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.rangeLabel {
  font-size: 12px;
}

.adjustStartBecomeFriend{
  position:relative;
  right:160px;
}

.textPaddingPlatformsNameBecomeFriend{
  padding-right:18px;
}

.adjustingImageAndTitleBecomeFriend{
  display:flex;
  align-items:center;
  justify-content:center;
}

.friendSimbolBecomeFriend{
  width:48px;
  height:48px;
}

.thisHeaderStyleBecomeFriend{
  height:9.6em;
  min-width:300px;
}

.divSeparingStyleHeaderBecomeFriend{
  top:0%;
}

.beOurGreatFriend{
  position:relative;
  top:-7.5em;
}

.beOurGreatFriend h1{
  color:#ff9c40;
}

.backgroundOfServiceBecomeFriend {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background: linear-gradient(to left, #1a1aff 0%, #00008b 100%);
  color: #ff9c40;
}

.backgroundOfServiceBecomeFriend::before {
  content: "";
  position: absolute;
  top: -87.7px;
  left: 0;
  width: 100%;
  min-width:335px;
  height: 23.9em;
  background-image: url("/public/backgroundBecomeAFriend.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  opacity: 0.3;
}

.adjustingLabelAndInput20 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
  margin-top:20px;
  max-width:700px;
}

.adjustingFormToBecameFriend{
  margin-bottom:30px;
}

.adjustingDivForForFriend{
  display:flex;
  align-items:center;
  justify-content:center;
}

.forHBecomeFriend20{
  margin:20px;
}

.adjustAtStart30 {
  position:relative;
  right:75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.error {
  border: 2px solid red;
}

.adjustingLabelAndInput20 {
  background: #e0e8f1;
}

.adjustTogheter30 {
  display: flex;
  align-items: center;
}

#shorterTextFriend {
  max-width: 50px;
}

#mediumTextFriend{
  max-width: 100px;
}

#largeTextFriend{
  max-width: 250px;
}

#longestTextFriend {
  width: 450px;
}

.recaptchaAndCheckmarkLine30 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorHighlight {
  outline: 2px solid red;
}

.thisCheckMarkStyling30 {
  padding-left: 110px;
}

.adjustingLabelAndInput20 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #ffe0b2;
  padding: 50px;
  border-radius: 12px;
}

.textWithSameSize30 {
  text-align: left;
  width: 118px;
}

.textWithSameSize30.textWithSameSize40 {
  width: 60px;
}

.centeringThisThings {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  margin-top: 10px;
}

input,
label {
  margin: 10px;
}

@keyframes float2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 876px) {

  .adjustAtStart30 {
    position:static;
  }

  .recaptchaAndCheckmarkLine30 {
    flex-direction: column;
  }

  .thisCheckMarkStyling30 {
    padding-left: 0;
  }

  .adjustTogheter30 {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  input {
    width: auto;
  }

  .textWithSameSize30,
  .textWithSameSize30.textWithSameSize40 {
    width: 95px;
  }

  #longestTextFriend {
    width: 180px;
  }
}

@media screen and (max-width: 555px) {
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 480px) {
  span {
    font-size: 12px;
  }

  .textWithSameSize30,
  .textWithSameSize30.textWithSameSize40 {
    width: 80px;
  }
  .recaptchaAndCheckmarkLine30 {
    width: 200px;
  }

  input {
    width: 120px;
  }

  html {
    overflow-x: hidden;
  }

  input,
  label {
    margin: 4px;
  }

  #shorterTextFriend {
    max-width: 30px;
  }

  #mediumTextFriend,
  #largeTextFriend,
  #longestTextFriend {
    max-width: 120px;
  }

  .recaptchaStyle {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 399px) {
  .beOurGreatFriend h1{
    font-size:30px;
  }
}

@media screen and (max-width: 356px) {
  html {
    overflow-x: auto;
  }

}
